<template>
  <v-container style="height: 520px; overflow-y: auto">
    <v-system-bar dark color="#777">
      <v-btn text @click="goto('service-details')">
        Service details
      </v-btn>
      <v-btn text @click="goto('discount')">
        Discount
      </v-btn>
      <v-btn text @click="goto('connection-info')">
        Connection details
      </v-btn>
    </v-system-bar>

    <v-card v-if="showServiceDetails" flat class="transparent mt-8">
      <table class="my-8 mx-auto" style="width: 420px">
        <tr>
          <td  v-if="serviceError" width="48" style="border: none; text-align: center">
            <v-icon color="#d00">mdi-alert</v-icon>
            {{ serviceId }}
          </td>
          <td width="360" style="border: none">
            <SearchService
              :serviceId.sync="serviceId"
              :partnerId="partnerId"
              :serviceName.sync="serviceName"
            />
          </td>
        </tr>
      </table>

      <table>
        <tr v-for="(record, index) of log" :key="index">
          <td width="36">
            <v-icon small color="primary">mdi-delete</v-icon>
          </td>
          <td width="120">{{ record.date }}</td>
          <td width="320">{{ record.status }}</td>
          <td>
            <EditServiceLogRecordDate
              :status="record.status"
              :activationDate.sync="activationDate"
              :suspendDate.sync="suspendDate"
              :suspendedDate.sync="suspendedDate"
              :cancelDate.sync="cancelDate"
              :canceledDate.sync="canceledDate"
            />
          </td>
        </tr>
        <tr>
          <td>
            <v-icon v-if="!newStatus" @click="appendStatus">
              mdi-plus
            </v-icon>
          </td>
          <td>{{ (new Date()).toISOString().slice(0, 10) }}</td>
          <td>
            <SelectServiceStatus
              v-if="newStatus"
              :status.sync="newStatusValue"
            />
          </td>
          <td>
            <EditServiceLogRecordDate
              v-if="newStatus"
              :status="newStatusValue"
              :activationDate.sync="newStatusDate"
              :suspendDate.sync="newStatusDate"
              :suspendedDate.sync="newStatusDate"
              :cancelDate.sync="newStatusDate"
              :canceledDate.sync="newStatusDate"
            />
          </td>
          <td width="36">
            <v-icon
              v-if="newStatus && newStatusValue && newStatusDate"
              color="primary"
              @click="confirmNewStatus"
            >
              mdi-checkbox-marked-outline
            </v-icon>
          </td>
        </tr>
      </table>
    </v-card>

    <EditCustomerDiscountInfo
      v-if="showDiscount"
      :serviceIndex="serviceIndex"
      :customerIndex="index"
    />

    <EditCustomerServiceConnectionInfo
      v-if="showConnectionInfo"
      :index="index"
      :serviceIndex="serviceIndex"
    />
  </v-container>
</template>

<script>

import { accessRightsHandler } from '@/controllers/data-handlers'
import { customerController } from '@/components/customers/import/controllers'

import EditCustomerDiscountInfo from '@/components/customers/EditCustomerDiscountInfo.vue'
import EditCustomerServiceConnectionInfo from '@/components/customers/import/EditCustomerServiceConnectionInfo.vue'
// import DatePicker from '@/components/inputs/DatePicker.vue'

export default {
  name: 'EditCustomerServiceDetails',

  components: {
    SearchService: () => import('@/components/inputs/SearchService.vue'),
    SelectServiceStatus: () => import('@/components/inputs/SelectServiceStatus.vue'),
    EditServiceLogRecordDate: () => import('@/components/customers/import/EditServiceLogRecordDate.vue'),
    // DatePicker,
    EditCustomerDiscountInfo,
    EditCustomerServiceConnectionInfo
  },

  props: ['serviceIndex', 'updated'],

  data: () => ({
    accessRights: accessRightsHandler().access.services,

    ready: false,

    index: customerController.getIndex(),
    service: customerController.getCurrentService(),
    partnerId: customerController.resellerId,
    newStatus: false,
    newStatusValue: '',
    newStatusDate: null,

    log: [],

    showServiceDetails: true,
    showDiscount: false,
    showConnectionInfo: false,

    serviceError: false
  }),

  computed: {
    serviceId: {
      get () {
        return this.service.id
      },
      set (id) {
        Object.assign(this.service, { id })
        customerController.updateService(id, id ? customerController.getServiceNameById(id) : '')
        this.$emit('update:updated', true)
        this.$nextTick(() => { this.serviceError = !id })
      }
    },

    serviceStatus: {
      get () {
        return this.service.status
      },
      set (status) {
        console.log('NEW STATUS: ', status)
        Object.assign(this.service, { status })
        customerController.changeServiceStatus(status)
      }
    },

    // newStatusValue: {
    //   get () {
    //     return this.service.status
    //   },
    //   set (status) {
    //     console.log('NEW STATUS: ', status)
    //     Object.assign(this.service, { status })
    //     customerController.changeServiceStatus(status)
    //   }
    // },

    activationDate: {
      get () {
        return this.service.activationDate
      },
      set (date) {
        Object.assign(this.service, { activationDate: date })
      }
    },

    suspendDate: {
      get () {
        return this.service.suspendDate
      },
      set (date) {
        Object.assign(this.service, { suspendDate: date })
      }
    },

    suspendedDate: {
      get () {
        return this.service.suspendedDate
      },
      set (date) {
        Object.assign(this.service, { suspendedDate: date })
      }
    },

    cancelDate: {
      get () {
        return this.service.cancelDate
      },
      set (date) {
        Object.assign(this.service, { cancelDate: date })
      }
    },

    canceledDate: {
      get () {
        return this.service.canceledDate
      },
      set (date) {
        Object.assign(this.service, { canceledDate: date })
      }
    }
  },

  watch: {
    serviceIndex (val) {
      this.setService()
    }
  },

  methods: {
    goto (label) {
      this.showServiceDetails = label === 'service-details'
      this.showDiscount = label === 'discount'
      this.showConnectionInfo = label === 'connection-info'
    },

    appendStatus () {
      this.newStatus = true
      this.newStatusValue = this.serviceStatus === 'Active' ? 'Canceled' : 'Active'
      this.newStatusDate = null
    },

    confirmNewStatus () {
      const tmp = [
        { status: 'Active', prop: 'activationDate', method: 'updateServiceActivationDate' },
        { status: 'Awaiting to be suspended', prop: 'suspendDate', method: 'updateServiceSuspendDate' },
        { status: 'Suspended', prop: 'suspendedDate', method: 'updateServiceSuspendedDate' },
        { status: 'Awaiting for cancelation', prop: 'cancelDate', method: 'updateServiceCancelDate' },
        { status: 'Canceled', prop: 'canceledDate', method: 'updateServiceCanceledDate' }
      ]
      const record = tmp.find(item => item.status === this.newStatusValue)

      if (!record) return

      this.serviceStatus = this.newStatusValue
      this[record.prop] = this.newStatusDate
      Object.assign(this.service.log, {
        [Date.now().toString()]: this.newStatusValue
      })

      customerController.changeServiceStatus(this.newStatusValue)
      customerController[record.method](this.newStatusDate)

      this.getServiceLog()

      this.newStatus = false
    },

    setService () {
      this.ready = false

      const service = customerController.getCurrentService()

      const { serviceName, id: serviceId, status: serviceStatus, activationDate, suspendDate, suspendedDate, cancelDate, canceledDate } = service
      Object.assign(this, {
        service,
        serviceName,
        serviceId,
        serviceStatus,
        activationDate,
        suspendDate,
        suspendedDate,
        cancelDate,
        canceledDate
      })

      this.getServiceLog()

      this.$nextTick(() => { this.ready = true })
    },

    getServiceLog () {
      this.log = []
      for (const date in this.service.log) {
        this.log.push({
          date: (new Date(date - 0)).toISOString().slice(0, 10),
          status: this.service.log[date]
        })
      }
    }
  },

  created () {
    this.setService()

    this.partnerId = customerController.resellerId

    if (!this.service.discount) customerController.initDiscount()
  }
}
</script>

<style scoped>
table {
  width: 100%;
  margin-bottom: 48px;
}
tr {
  margin-bottom: 32px;
}
td {
  font-size: 14px;
  padding: 4px 12px;
  border: solid 1px #ddd;
}

.theme--light.v-application .text--primary {
  color: #900!important;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: transparent !important;
  box-shadow: none !important;
}

#service-autocomplete-container {
  position: relative;
}
#service-autocomplete-container > .v-autocomplete__content.v-menu__content {
  position: relative !important;
  top: 204px !important;
  left: 616px !important;
  max-height: 280px !important;
}
</style>
